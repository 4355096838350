.header-menu-icon {
    @apply absolute text-blue-800;
    top: 1rem;
    right: 1rem;
    @screen md {
        @apply hidden;
    }
}

.nav-open {
    ul {
        @apply block py-6 pl-0 text-center;
    }
    ~ .hp-title_box {
        @apply hidden;
    }
}
