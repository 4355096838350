.herobar {
    @apply w-full h-120 bg-cover bg-no-repeat;
    background-position: center 7rem;
    @screen md {
        @apply h-140 bg-center;
    }
    &-author {
        background-image: url('/app/themes/default/images/herobar-author.jpg');
    }
    &-blog {
        background-image: url('/app/themes/default/images/blog.jpg');
    }
    &-coach {
        background-image: url('/app/themes/default/images/executive-coach.jpg');
    }
    &-keith {
        background-image: url('/app/themes/default/images/keith-shields-04.jpg');
    }
    &-orgdev {
        background-image: url('/app/themes/default/images/organizational-development.jpg');
    }

}

.herobar-hp {
    @apply h-40;
    background-image: url('/app/themes/default/images/keith-shields-04.jpg');
    @screen md {
        @apply h-160;
    }
}

.herobar-hp-mobile {
    @apply w-full h-120 bg-cover bg-center bg-no-repeat;
    background-position: -12rem;
    background-image: url('/app/themes/default/images/keith-shields-04.jpg');
    @screen md {
        @apply hidden;
    }
}

.herobar-author {
    background-image: url('/app/themes/default/images/herobar-author.jpg');
}
