.bg-honeycomb {
    @apply bg-gray-900;
    @screen md {
        background-image: url('/app/themes/default/images/bg-honeycomb.jpg');
    }
}

.honeycomb-content {
    @apply order-2 max-w-11/12 mx-auto mb-4 p-6 bg-white bg-opacity-75;
    @screen sm {
        @apply w-4/5;
    }
    @screen md {
        @apply order-1 w-3/5 mb-0;
    }
    @screen lg {
        @apply w-144;
    }
    a {
        @apply text-blue-700;
    }
    h2 a {
        @apply text-gray-700;
    }
}

.honeycomb-img_wrap {
    @apply order-1 w-4/5 mx-auto mb-6;
    @screen sm {
        @apply w-4/5;
    }
    @screen md {
        @apply order-2 w-2/5 mx-0 mb-0 pl-8;
    }
    @screen lg {
        @apply w-128;
    }
}

.honeycomb-img {
    @apply block max-h-80 w-auto mx-auto object-contain;
    @screen sm {
        @apply max-w-80;
    }
    @screen md {
        @apply max-w-full max-h-full mx-0;
    }
}

.honeycomb-button {
    @apply transform mt-6 opacity-75 transition-all duration-300 ease-in-out;
    &:hover {
        @apply -translate-y-1 opacity-100;
    }
}
