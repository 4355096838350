/* this file is for public and private/admin pages */

a, .button-link {
    @apply text-blue-500 no-underline transition-colors duration-300 ease-in-out;
    &:hover, &:active {
        @apply underline text-blue-700;
    }
    &:disabled {
        @apply opacity-75;
        &:hover {
            @apply no-underline cursor-default;
        }
    }
}

.button-link {
    @apply font-light;
}

b, strong {
    @apply font-bold;
}

// re-add list styles as tailwind removes them
ul, ol {
    @apply pl-10;
    li {
        @apply mb-4;
    }
}
ul {
    @apply list-disc;
}
ol {
    @apply list-decimal;
}
address {
    @apply not-italic;
}

td {
    @apply align-top;
}

.transition {
    @apply transition-all duration-300 ease-in-out;
}
