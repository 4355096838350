/* purgecss start ignore */
@tailwind base;
@tailwind components;

@import "common";

// global styles on the most common elements
html {
    @apply font-sans leading-normal text-black;
}

// common styles
@import "alerts",
    "content_alignment",
    "forms",
    "headings";

// public only styles
@import "content",
    "buttons",
    "header",
    "sidenav",
    "herobar",
    "cards",
    "blog",
    "honeycomb",
    "reviews",
    "embed";

// wp editor styles
@import "wp_editor", "wp_block_embed";

// page specific
@import "p_index";

/* purgecss end ignore */
@tailwind utilities;
