.w-content, .w-content-narrow, .w-content-copy {
    @apply max-w-11/12 mx-auto;
}
.w-content {
    @screen xl {
        @apply max-w-6xl;
    }
}
.w-content-narrow {
    @screen sm {
        @apply max-w-xl;
    }
    @screen lg {
        @apply max-w-4xl;
    }
    @screen xl {
        max-width: 59.5rem;
    }
}
.w-content-copy {
    @screen sm {
        @apply max-w-xl;
    }
    @screen xl {
        @apply max-w-xl;
    }
}

.bg-blue-abstract {
    @apply bg-blue-700;
    background-image: url('/app/themes/default/images/bg-blue.jpg');
}

.bg-slate {
    @apply bg-cover bg-no-repeat bg-gray-900;
    background-image: url('/app/themes/default/images/slate.jpg');
}

.bg-quote {
    @apply relative bg-no-repeat z-20;
    background-position: -4rem;
    background-image: url('/app/themes/default/images/quote.svg');
}

.excerpt-wrap {
    @apply text-lg;
    @screen lg {
        @apply text-2xl;
    }
    ul, ol {
        @apply p-0;
    }
}
