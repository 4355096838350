.alert {
    @apply flex justify-between max-w-2xl mt-0 mx-auto mb-1 p-2 bg-gray-200 border-l-10;

    // alerts nested inside forms should go the full width
    .form-wrap & {
        @apply w-full;
    }
}

.alert-success {
    @apply border-green-500;
}
.alert-info {
    @apply border-blue-400;
}
.alert-warning {
    @apply border-orange-500;
}
.alert-danger {
    @apply border-red-500;
}
