.cards-wrap {
    @apply flex flex-col flex-wrap justify-center items-center max-w-11/12 mx-auto;
    @screen md {
        @apply flex-row;
    }
}

.card-link {
    @apply flex flex-col justify-between w-3/4 mb-8 mx-4 text-blue-800 border border-gray-800 rounded shadow;
    &:hover {
        @apply shadow-md no-underline;
    }
    @screen sm {
        @apply w-56;
    }
}

.card-img {
    @apply  w-full h-56 rounded-t object-cover object-bottom;
    @screen md {
        @apply w-56;
    }
}

.card-heading_wrap {
    @apply flex justify-center items-center h-20 p-4;
}
