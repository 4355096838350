.blog_list-thumbnail-wrap {
    @apply block py-4;
    @screen md {
        @apply w-1/3 pr-4 pb-0;
    }
    img:first-child {
        @apply block mx-auto;
    }
}

.blog-post-wrap img {
    @apply inline-block;
}

.page-numbers {
    @apply inline-block mx-2 mb-4 px-4 py-2 text-sm border border-gray-400;
    &:hover {
        @apply shadow no-underline;
    }
    &.current {
        @apply bg-gray-200;
        &:hover {
            @apply shadow-none;
        }
    }
}
