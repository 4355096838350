.review-quote-icon {
    @apply -mt-2 mb-8 -ml-4 pt-8 pl-6 bg-no-repeat ;
    background-image: url('/app/themes/default/images/quote.svg');
    background-position: left 0.75em top 1em;

    @screen md {
        @apply -ml-12 pl-12;
        background-position: left 0em top 1em;
    }
}

.review-quote-caption {
    @apply max-w-sm mt-2 ml-auto text-sm text-right italic text-gray-600 font-thin;
    @screen md {
        @apply text-base;
    }
}
