// Leading resets tailwinds default line-height
.button {
    @apply py-3 px-4 text-white bg-blue-800 shadow-none border border-blue-800 rounded leading-tight transition-all duration-300 ease-in-out;
    &:hover {
        @apply text-white bg-blue-600 border-blue-600 no-underline transition-all duration-300 ease-in-out;
    }
}

a.button {
    @apply inline-block;
}
