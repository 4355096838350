.sidenav {
    @apply w-full;
    @screen md {
        max-width: 14em;
        margin-right: 2rem;
    }
}

.sidenav_item {
    @apply mb-4 font-bold;
    @screen md {
        @apply mb-6 text-xl;
    }
    &:last-of-type {
        @apply mb-0;
    }
    &:hover {
        @apply no-underline;
    }
}
